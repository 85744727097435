@media only screen and (min-width: 601px) {
  .bannerDiv {
    min-height: 110vh;
  }

  .banner {
    font-family: jetBrains;
    margin: 3px;
    color: black;
  }

  .name {
    text-align: center;
    width: 100%;
    font-size: 110px;
    font-weight: bold;
    color: #5cdfe4;
    filter: drop-shadow(5px 5px 5px #ffffff);
  }

  .name p {
    margin: 3%;
  }

  .sorcerer {
    font-size: 40px;
    width: 100%;
    text-align: center;
    color: green;
    filter: drop-shadow(3px 3px 2px #ffffff);
  }

  .bannerBullets {
    font-size: 20px;
    padding: 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    list-style-type: none;
  }

  .bannerBullets li {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .bannerDiv {
    min-height: 110vh;
  }

  .banner {
    font-family: jetbrains;
    margin: 3px;
    color: black;
  }

  .name {
    width: 75%;
    margin-top: 50%;
    margin-left: 0%;
    font-size: 60px;
    font-weight: bold;
    color: #5cdfe4;
    filter: drop-shadow(3px 3px 2px #ffffff);
  }

  .titleAndSub {
    display: flex;
    flex-direction: column;
  }

  .sorcerer {
    width: 100%;
    text-align: center;
    color: green;
    filter: drop-shadow(3px 3px 2px #ffffff);
  }

  .name p {
    margin: 0px;
  }

  .bannerBullets li {
    list-style-type: square;
    padding-top: 30px;
    padding: 5%;
    margin: 0%;
  }
}
