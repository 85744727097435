@media only screen and (min-width: 601px) {
  .contactDiv {
    min-height: 50vh;
    background-color: #aaf1fbe3;
    padding: 1%;
    z-index: 1;
    margin-top: 100px;
  }

  .pingMe {
    font-size: 40px;
    font-family: jetBrains;
    color: #008612;
    text-align: center;
    filter: drop-shadow(3px 3px 2px #ffffff);
  }

  .contactInfo {
    color: black;
    text-align: center;
    font-size: 18px;
  }

  .contactImg {
    width: 50%;
  }

  .contactLinks {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
}

@media only screen and (max-width: 600px) {
  .contactDiv {
    min-height: 40vh;
    background-color: #aaf1fbe3;
    padding: 1%;
    margin-top: 10vh;
  }

  .pingMe {
    font-family: jetBrains;
    color: #008612;
    text-align: center;
    filter: drop-shadow(3px 3px 2px #ffffff);
  }

  .contactInfo {
    color: black;
    text-align: center;
  }

  .contactImg {
    width: 90%;
  }

  .contactLinks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
}
