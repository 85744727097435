@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai+Looped:wght@500&display=swap");

@font-face {
  font-family: "jetBrains";
  src: url("/public/Fonts/JetBrainsMono-Regular.woff2") format("woff");
}

@font-face {
  font-family: "jetBrains";
  src: url("../public/Fonts/JetBrainsMono-Bold.woff2") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "jetBrains";
  src: url("../public/Fonts/JetBrainsMono-BoldItalic.woff2") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "jetBrains";
  src: url("../public/Fonts/JetBrainsMono-Italic.woff2") format("woff");
  font-style: italic;
}

@media only screen and (min-width: 601px) {
  html {
    width: 100%;
  }
  body {
    width: 100%;
    background-color: #d6d6d6;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: white;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-shrink: 0;
  }

  .footerDiv {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  html {
    width: 100%;
  }
  body {
    width: 100%;
    background-color: #d6d6d6;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: white;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-shrink: 0;
  }
}
