@media only screen and (min-width: 601px) {
  .projectsDiv {
    font-family: "IBM Plex Sans Thai Looped", sans-serif;
    background-color: #bdbdbd;
    display: flex;
    flex-direction: column;
    color: black;
  }

  .projectsTitle {
    font-size: 90px;
    font-family: jetBrains;
    color: #008612;
    text-align: center;
    filter: drop-shadow(3px 3px 2px #ffffff);
  }

  .projectImg {
    width: 40%;
    text-align: center;
    border: 10px solid white;
    margin: 0 auto;
  }

  .project {
    position: relative;
    overflow: visible;
    text-align: center;
    margin: 1%;
    padding-top: 12%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .projectNameAndDesc {
    background: #ffffffa0;
    color: black;
    width: 60%;
    margin: 0 auto;
    border-radius: 4px;
  }

  .projectContent {
    text-align: center;
    height: 130vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .projectName {
    font-size: 40px;
    text-align: center;
    border-radius: 10px;
    color: black;
    margin: 2% auto;
  }

  .projectTagline {
    font-style: italic;
    font-size: 25px;
  }

  .projectLink {
    font-size: 30px;
    background-color: #71e7f7;
    text-decoration: none;
    padding: 4px;
    border-radius: 10px;
    width: 30%;
    margin: 0 auto;
  }

  .projectDesc {
    background: #ffffffa0;
    border-radius: 4px;
    width: 60%;
    color: black;
    margin: 2% auto;
    padding-left: 2%;
    padding-right: 2%;
    font-size: 18px;
  }

  .foodDiv {
    z-index: 1;
    padding-top: 0;
    margin-top: 0;
    justify-content: start;
    margin-bottom: 0;
  }

  .foodDiv::before {
    background: #c3f6ff;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    position: absolute;

    clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 75%);
  }

  .hogDiv {
    z-index: 1;
    padding-top: 0;
    margin-top: 0;
    justify-content: start;
    margin-bottom: 0;
  }

  .hogDiv::before {
    background: #d6d6d6;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    position: absolute;

    clip-path: polygon(0 0, 100% 25%, 100% 75%, 0 100%);
  }

  .reviewDiv {
    z-index: 1;
    padding-top: 0;
    margin-top: 0;
    justify-content: start;
    margin-bottom: 0;
  }

  .reviewDiv::before {
    background: #c3f6ff;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    position: absolute;

    clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 75%);
  }

  .catDiv {
    z-index: 1;
    padding-top: 0;
    margin-top: 0;
    justify-content: start;
    margin-bottom: 0;
  }

  .catDiv::before {
    background: #d6d6d6;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    position: absolute;

    clip-path: polygon(0 0, 100% 25%, 100% 75%, 0 100%);
  }

  .farmDiv {
    color: black;
    z-index: 1;
  }
  .farmDiv::before {
    background: #c3f6ff;
    content: "";
    height: 110%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    position: absolute;

    clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 75%);
  }

  .tareDiv {
    color: black;
    z-index: 1;
  }

  .tareDiv::before {
    background: #d6d6d6;
    content: "";
    height: 110%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    position: absolute;

    clip-path: polygon(0 0, 100% 25%, 100% 75%, 0 100%);
  }

  .sootDiv {
    z-index: 1;
  }

  .sootDiv::before {
    background: #c3f6ff;
    content: "";
    height: 110%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    position: absolute;

    clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 75%);
  }

  .copDiv {
    color: black;
    z-index: 1;
    justify-content: end;
    padding-top: 16%;
  }

  .copDiv::before {
    background: #d6d6d6;
    content: "";
    height: 105%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    position: absolute;

    clip-path: polygon(0 0, 100% 25%, 100% 100%, 0 100%);
  }
}

@media only screen and (max-width: 600px) {
  .projectsDiv {
    font-family: "IBM Plex Sans Thai Looped", sans-serif;
    background-color: #bdbdbd;
    display: flex;
    flex-direction: column;
    color: black;
  }

  .projectsTitle {
    font-family: jetBrains;
    color: #008612;
    text-align: center;
    filter: drop-shadow(3px 3px 2px #ffffff);
  }

  .projectImg {
    width: 70%;
    text-align: center;
    margin: 0 auto;
  }

  .project {
    position: relative;
    overflow: visible;
    text-align: center;
    margin: 1%;
    padding-top: 12%;
    min-height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .projectNameAndDesc {
    background: #ffffffa0;
    color: black;
    width: 60%;
    margin: 0 auto;
    border-radius: 4px;
  }

  .projectContent {
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .projectName {
    font-size: x-large;
    text-align: center;
    border-radius: 10px;
    color: black;
    margin: 2% auto;
  }

  .projectLink {
    background-color: #71e7f7;
    text-decoration: none;
    font-size: larger;
    padding: 4px;
    border-radius: 10px;
    width: 60%;
    margin: 0 auto;
  }

  .projectDesc {
    background: #ffffffa0;
    border-radius: 4px;
    width: 90%;
    color: black;
    margin: 2% auto;
    padding-left: 2%;
    padding-right: 2%;
  }

  .foodDiv {
    z-index: 1;
    padding-top: 0;
    margin-top: 0;
    justify-content: start;
    margin-bottom: 0;
    background-color:red;
  }

  .foodDiv::before {
    background: #c3f6ff;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    position: absolute;

    clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 75%);
  }

  .hogDiv {
    z-index: 1;
    padding-top: 0;
    margin-top: 0;
    justify-content: start;
    margin-bottom: 0;
  }

  .hogDiv::before {
    background: #d6d6d6;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    position: absolute;

    clip-path: polygon(0 0, 100% 25%, 100% 75%, 0 100%);
  }

  .reviewDiv {
    z-index: 1;
    padding-top: 0;
    margin-top: 0;
    justify-content: start;
    margin-bottom: 0;
  }

  .reviewDiv::before {
    background: #c3f6ff;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    position: absolute;

    clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 75%);
  }

  .catDiv {
    z-index: 1;
    padding-top: 0;
    margin-top: 0;
    justify-content: start;
    margin-bottom: 0;
  }

  .catDiv::before {
    background: #d6d6d6;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    position: absolute;

    clip-path: polygon(0 0, 100% 25%, 100% 75%, 0 100%);
  }

  .farmDiv {
    color: black;
    z-index: 1;
  }
  .farmDiv::before {
    background: #c3f6ff;
    content: "";
    height: 110%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    position: absolute;

    clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 75%);
  }

  .tareDiv {
    color: black;
    z-index: 1;
  }

  .tareDiv::before {
    background: #d6d6d6;
    content: "";
    height: 110%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    position: absolute;

    clip-path: polygon(0 0, 100% 25%, 100% 75%, 0 100%);
  }

  .sootDiv {
    z-index: 1;
  }

  .sootDiv::before {
    background: #c3f6ff;
    content: "";
    height: 110%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    position: absolute;

    clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 75%);
  }

  .copDiv {
    color: black;
    z-index: 1;
    justify-content: end;
    padding-top: 16%;
  }

  .copDiv::before {
    background: #d6d6d6;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    position: absolute;

    clip-path: polygon(0 0, 100% 25%, 100% 100%, 0 100%);
  }
}
